a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.nav-link{
  margin: 5px;
  padding: unset;
  color: #fff !important;
}

.nav-link:hover{
  border-bottom: solid 1px;
  color: #d9ebf9 !important;
}

.navbar{
  /* background-color: #07adee; */
  background-color: #515151;
}

.navbar-brand{
  color: #fff8f8;
}