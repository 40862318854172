/* Provide sufficient contrast against white background */
body {
  background-color: #353535;
  background-image: url(/public/dark_office.jpg);
  background-repeat: repeat;
  background-size: cover;
  font-family: 'Arial', 'Roboto', 'Noto Sans Arabic', sans-serif;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.text-warning-dark {
  color: #db5200 !important;
}

.custom-alert {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  color: #818182;
  background-color: #fefefe;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin: 1rem 0px;
  border: 1px solid transparent;
  border-bottom: 2px solid #e01a76;
  border-radius: 0.25rem;
  transition: opacity 0.15s linear;
}

.custom-alert > p {
  padding: 5px 0px !important;
  margin: 0 !important;
}
.custom-alert > p > button {
  cursor: pointer;
  color: #007bff !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  --bs-text-opacity: 1;
  float: right !important;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
}

#main-container {
  min-height: 60vh;
}

.details-box {
  min-height: 80vh;
}

.loader-box {
  min-height: 50vh;
}

.details-box,
.loader-box {
  /* background-color: #1a1a1a; */
  background-color: #222;
  border-bottom: 2px solid #88898a;
  border-left: 4px solid #88898a;
  border-right: 2px solid #88898a;
  color: #fff !important;
  min-width: 80vw;
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */ 
  background-color: rgba(0, 0, 0, 0.93);
  z-index: 1000;
}

.fullscreen-container > .content {
  background-color: #42525f; /* Background color for content */
  padding: 1.5em 3em;
  border-radius: 5px;
}

.card-images-large {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  border: #fefefe solid 2px;
  border-radius: 10px;
}

.card-images {
  max-width: 100%;
  height: 200px;
  /* width: 320px; */
  border: #fefefe solid 2px;
  border-radius: 10px;
}

.card-light {
  background-color: #222;
  padding: 2px;
  padding: 1em;
}

.image-preview {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  max-width: 100%;
  overflow-x: auto;
  margin-top: 1em;
}

.image-item {
  min-width: 320px;
}

.image-item > button {
  position: relative;
}

.image-item > .btn-actions {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -30px;
}

/* 
.image-item > .btn-del {
  top: 0px;
}

.image-item > .btn-copy {
  bottom: 30px;
} */

.hide-behind {
  z-index: -1000;
  position: absolute;
  background-color: inherit !important;
  border: unset !important;
}

body {
  background-color: #353535;
}
.link {
  color: #fff;
  background-color: #007bff;
  padding: 2px 15px;
  margin: 2px;
}
.link:hover {
  background-color: #0366d6;
  color: #fff;
  border-radius: 5px;
}

.description {
  color: #686767;
}
.lst {
  border-bottom: #939393 2px solid;
  margin-bottom: 2px;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 350px;
  padding: 10px 14px;
  color: var(--panel-color);
  background-color: var(--text-color);
  border-right: 1px solid var(--border-color);
  transition: var(--tran-05);
  overflow: auto;
  z-index: 1000000000;
}
nav.close {
  width: 0px;
  display: none;
}
nav .logo-name {
  display: flex;
  align-items: center;
}
nav .logo-image {
  display: flex;
  justify-content: center;
  max-width: 80%;
}
nav .logo-image img {
  /* width: 40px; */
  /* object-fit: cover; */
  border-radius: 25px;
  max-height: 100px;
  max-width: 100%;
}

.navbar-toggler-icon {
  cursor: pointer;
  margin-top: 1.1em !important;
  background-color: #848484;
  position: absolute;
}

.sidebar-toggle1 {
  cursor: pointer;
  color: #000;
  top: 0px;
  right: 10px;
  position: absolute;
}

.nav-input {
  max-height: 35px;
}

nav .logo-name .logo_name {
  font-size: 22px;
  font-weight: 600;
  color: var(--title-icon-color);
  margin-left: 14px;
  transition: var(--tran-05);
}
nav.close .logo_name {
  /* opacity: 0; */
  pointer-events: none;
}
nav .menu-items {
  margin-top: 40px;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-items li {
  list-style: none;
}
.menu-items li a {
  display: flex;
  align-items: center;
  height: 50px;
  text-decoration: none;
  position: relative;
}
.nav-links li:hover,
.logout-mode li:hover {
  background-color: #ffffff;
  color: #000;
  border-radius: 5px;
  list-style: none;
  border-left: 5px solid var(--primary-color);
}
body.dark li a:hover:before {
  background-color: var(--text-color);
}
.dropdown-item {
  background-color: #eee;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: small;
  color: #2000ff;
  padding-left: 15%;
}
.dropdown-item:hover {
  background-color: #e0d7d7;
  color: #007bff;
}
.menu-items li a i {
  font-size: 18px;
  min-width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black-light-main-color);
}
.menu-items li a .link-name {
  font-size: 18px;
  font-weight: 400;
  color: var(--black-light-main-color);
  transition: var(--tran-05);
}
nav.close li a .link-name {
  /* opacity: 0; */
  pointer-events: none;
}
.nav-links li a:hover i,
.nav-links li a:hover .link-name {
  color: var(--primary-color);
}
body.dark .nav-links li a:hover i,
body.dark .nav-links li a:hover .link-name {
  color: var(--text-color);
}
.logout-mode {
  padding-top: 10px;
  border-top: 1px solid var(--border-color);
}
.menu-items {
  padding-top: 10px;
  border-top: 1px solid var(--border-color);
  margin-top: 10vh;
}
.menu-items .mode {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menu-items .mode-toggle {
  position: absolute;
  right: 14px;
  height: 50px;
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mode-toggle .switch {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
}
.switch:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-color: var(--panel-color);
  border-radius: 50%;
  transition: var(--tran-03);
}
body.dark .switch:before {
  left: 20px;
}

.dashboard {
  position: relative;
  /* left: 250px; */
  background-color: var(--panel-color);
  /* min-height: 100vh; */
  /* width: calc(100% - 250px); */
  width: 100%;
  padding: 10px 14px;
  transition: var(--tran-05);
}
nav.close ~ .dashboard {
  left: 0px;
  width: 100%;
}
.dashboard .top {
  position: fixed;
  top: 0;
  /* left: 250px; */
  display: flex;
  /* width: calc(100% - 250px); */
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  background-color: var(--text-color);
  color: var(--gold-color);
  transition: var(--tran-05);
  z-index: 10;
}
nav.close ~ .dashboard .top {
  left: 0px;
  width: 100%;
}
.dashboard .top .sidebar-toggle {
  font-size: 26px;
  color: var(--black-light-main-color);
  cursor: pointer;
}
.dashboard .top .search-box {
  position: relative;
  height: 45px;
  max-width: 300px;
  width: 100%;
  margin: 0 30px;
}
.top .search-box input {
  position: absolute;
  border: 1px solid var(--border-color);
  background-color: var(--panel-color);
  padding: 0 25px 0 50px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 400;
  outline: none;
}
.top .search-box i {
  position: absolute;
  left: 15px;
  font-size: 22px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  color: var(--black-light-color);
}

.dashboard .dash-content {
  padding-top: 15px;
}
.dash-content .title {
  display: flex;
  align-items: center;
  margin: 60px 0 30px 0;
}
.dash-content .title i {
  position: relative;
  height: 35px;
  width: 35px;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: var(--title-icon-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.dash-content .title .text {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color);
  margin-left: 10px;
}
.dash-content .box {
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 2px rgb(75, 75, 75) solid;
  padding: 15px 20px;
  background-color: #0d6efd;
  transition: var(--tran-05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dash-content .box:hover {
  background-color: var(--black-light-main-color);
  box-shadow: none;
}
.box i {
  font-size: 35px;
  color: var(--text-color);
}
.box .text {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
}
.box .number {
  font-size: 40px;
  font-weight: 500;
  color: var(--text-color);
}
.box.box2 {
  background-color: #ffacac;
}
.box.box3 {
  background-color: var(--box3-color);
}
.dash-content .activity .activity-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-header {
  display: flow-root;
}

h6 > a {
  margin: 2px;
}

.control-label {
  display: block;
}
input[type="text"],
input[type="date"] {
  border: #1b6ec2 1px solid;
}
input,
select {
  background-color: #ececec !important;
}
input[type="text"]:disabled,
input[type="date"]:disabled,
.form-control:disabled {
  border: rgb(79, 79, 79) 1px solid !important;
  color: #d5d5d5 !important;
  background-color: #484848 !important;
}

input[type="checkbox"] {
  max-width: 30px;
  scale: 1.2;
}
span {
  max-width: fit-content !important;
}
button > span,
a > i {
  margin: 3px;
}

.form-control-sm {
  max-width: 130px;
}

li {
  list-style: none;
}
.navigation > ul {
  display: block ruby;
}
.navigation > ul > li {
  margin-right: 5px;
}

.listitem {
  margin-top: 15px;
}
.itemtitle {
  font-weight: bold;
  padding-left: 10px;
}
.attendance-box {
  width: 350px;
  max-width: 100%;
  background-color: #202020;
  min-height: 400px;
  border-radius: 10px;
}
.attendance-box > .lst {
  justify-content: center;
  /* min-height: 450px; */
  border-left: #007bff solid 1px;
}
.attendance-box .lst,
.attendance-box > .lst {
  background-color: #202020;
  justify-content: center;
}

.attendance-box > a {
  color: inherit;
  text-decoration: none;
}
.hover-shadow:hover {
  box-shadow: 0 0 0.5rem rgb(19, 141, 241);
}

.ql-snow .ql-stroke {
  stroke: #fff !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before {
  color: #fff;
}

.modal-lg {
  min-width: 90vw;
}

.cert-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 1em;
}

.card-light-blue {
  background-color: #dbdbdb;
  color: black;
  border: solid 1px aliceblue;
}

.card-blue {
  background-color: #5a9bd5;
  color: #fff;
  border: solid 1px aliceblue;
}

.profile-header {
  border-bottom: solid 1px gray;
  display: flex;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
}

.graphic {
  max-width: 100%;
  max-height: 450px;
  height: 250px;
}

video {
  max-height: 50vh;
}
.ql-container {
  min-height: 150px;
}

.hidden {
  display: none;
}

.carousel-caption-inner {
  background-color: #ffffff8f;
  border-radius: 15px;
  padding: 5px 10px;
}
.nav-item > .nav-link {
  /* max-width: 90%; */
}
.disabled {
  background-color: #2f2f2f;
  color: #fff;
}

.bg1 {
  background-color: #ff0300;
}
.bg2 {
  background-color: #ff6600;
}
.bg3 {
  background-color: #ff9900;
}
.bg4 {
  background-color: #d3a900;
}
.bg5 {
  background-color: #ffcc00;
}
.bg6 {
  background-color: #ffff00;
}
.bg7 {
  background-color: #ccff00;
}
.bg8 {
  background-color: #99ff00;
}
.bg9 {
  background-color: #66ff00;
}
.bg10 {
  background-color: #33ff00;
}

.bgS {
  background-color: #33ff00;
}
.bgAp {
  background-color: #66ff00;
}
.bgA {
  background-color: #99ff00;
}
.bgA- {
  background-color: #ccff00;
}
.bgBp {
  background-color: #ffff00;
}
.bgB {
  background-color: #ffcc00;
}
.bgB- {
  background-color: #d3a900;
}
.bgCp {
  background-color: #ff9900;
}
.bgC {
  background-color: #ff6600;
}
.bgC- {
  background-color: #ff3300;
}
.bgDp {
  background-color: #ff0000;
}
.bgD {
  background-color: #ff0100;
}
.bgD- {
  background-color: #ff0200;
}
.bgF {
  background-color: #ff0300;
}
.bg10 {
  background-color: #33ff00;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  max-width: 350px;
  max-width: 350;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.circle {
  border-radius: 25px !important;
}

.details-span {
  font-size: 14px;
  font-weight: normal;
}

.dec-review-meta ul li span {
  min-width: 85px;
  clear: both;
  display: inline-block;
  text-align: left;
}
.dec-review-meta ul li span label {
  text-align: right;
  float: right;
}
.dec-review-meta ul li w,
.dec-review-meta ul li a {
  /* color: #5b5b5b; */
  color: #bbb;
  font-weight: normal;
  margin-left: 11px;
}
video {
  /* min-height: 60vh; */
}
.img-container > img {
  height: 60vh;
}
.img-profile > img {
  max-height: 150px;
}
.img-container {
  justify-content: center;
  text-align: center;
}
.placeholder-btn {
  position: relative;
  /* height: 60vh; */
  background-color: transparent;
  text-align: center;
  justify-content: center;
  top: -35vh;
}
.placeholder-btn > button {
  width: max-content;
}

.hover-zoom:hover {
  transform: scale(1.1);
  transition: all 0.4s ease-in-out;
}
.hover-zoom {
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.dboard-card {
  padding: 15px 5px;
  border-radius: 15px;
  /* max-width: 80%;
  min-width: max-content; */
  width: 20em !important;
}
.dboard-card > div {
  margin-bottom: 5px;
}
.lstattendance > .row > .form-group {
  margin-bottom: unset;
}
.lstattendance > .row > .form-group > label {
  cursor: pointer;
}

#side-nav {
  background-color: #fbfbfb;
}

.lstattendance {
  cursor: pointer;
}

.cert-icon {
  max-width: 50px;
}

.form-group {
  margin-bottom: 15px;
}
.sitelogo {
  max-height: 35px;
  margin-right: 5px;
}
.text-yellow {
  --bs-text-opacity: 1;
  color: #eadc0b !important;
}
ul > .btn {
  border: unset;
}
.img-lst {
  max-width: 100%;
  max-height: 200px;
}
.btn-icon {
  padding: 2px;
  align-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
}

.material-icons {
  font-size: 22px;
}

.custom-switch .form-check-input {
  /* Your specific styling for the switch */
  /* background-color: rgb(86, 209, 69) !important; */
  background-color: #fff !important;
}

.custom-switch .form-check-input:checked {
  background-color: #007bff !important; /* Example background color for 'false' state */
}


.react-paginate-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0 0.5em;
}

.react-paginate-nav:first-child,
.react-paginate-nav > ul {
  width: fit-content;
  max-width: 450px;
}

.react-paginate-nav > ul {
  display: block ruby;
}

.maxw-fit-content {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.vertical-line {
  border: 1px solid black;
  height: 50px;
  width: 0;
  margin: 1em auto; /* Adjust this margin according to your preference */
}

.bg-dark-light {
  background-color: #2c2c2d;
}

.projects-header {
  background-color: #4473c5;
  color: #fff;
}

.projects {
  /* background-color: #1582f7; */
  background-color: #5a9bd5;
  color: #fff;
}

.projects:nth-child(odd) {
  background-color: #4679af;
}

.project-box {
  border: solid 1px #ffdbac;
  border-right: solid 3px #ffacac;
}

.company-box {
  border: solid 1px #b3ffac;
  border-right: solid 3px #f3ffac;
}

.staff-box {
  border: solid 1px #d7acff;
  border-left: solid 3px #ffacf8;
  border-bottom: solid 3px #ffacf8;
}

.dashboard-block {
  min-width: 300px;
  max-width: fit-content;
  height: 50px;
  /* background-color: #548ac4; */
  background-color: #5a9bd5;
  color: #fff;
  padding: 10px;
}

.dashboard-block > label,  
.dashboard-block > a {
  margin: auto;
  text-decoration: none;
  color: #fff;
}
 
.dashboard-block > img {
  margin: auto;
}

.bg-dark-green {
  background-color: #0c151e !important;
}