
@media only screen and (min-width: 680px) {
  .material-icons {
    font-size: 24px;
  }
  
  .graphic {
    height: 450px;
  }

  .dboard-card {
    width: 24%;
  }

  .staff-col, .attendance-col {
    width: 20%;
  }
  
  .container {
    max-width: 90% !important;
  }
  
  .form-control {
    max-width: 380px !important;
  }
}
